<template>
  <div class="login-layout">
    <div class="logo">
      <van-image
        class="logoimg"
        width="30vw"
        height="30vw"
        round
        fit="contain"
        lazy-load
        :src="require('@/assets/images/logo.png')"
      />
    </div>
    <van-form class="login-form" @submit="onSubmit">
      <van-cell-group>

        <van-field
          v-model="username"
          :placeholder="PAGELANG.name"
          left-icon="aog iconfont icon-mobile"
          :rules="rules.username"
        />
		
		
        <van-field
          v-model="password"
          type="password"
          :placeholder="PAGELANG.password"
          left-icon="aog iconfont icon-password"
          :rules="rules.password"
        />
        <van-field
          v-model="password2"
          type="password"
          :placeholder="PAGELANG.password2"
          left-icon="aog iconfont icon-password"
          :rules="rules.password2"
        />
<!--        <van-field
          v-model="agentname"
          type="text"
          :placeholder="PAGELANG.agentname"
          left-icon="aog iconfont icon-my"
          :rules="rules.agentrequired"
        /> -->
		
		<van-field
		  v-model="identityCard"
		  type="text"
		  :placeholder="PAGELANG.huzhao"
		  left-icon="aog iconfont icon-email"
		   :rules="rules.identityCard"
		/>
		
		<van-field
		  v-model="email"
		  type="text"
		  :placeholder="PAGELANG.email"
		  left-icon="aog iconfont icon-email"
		  :rules="rules.email"
		/>
		
		
		
      </van-cell-group>

      <van-button
        v-if="isloading"
        color="var(--van-card-price-color)"
        block
        loading
        type="primary"
        :loading-text="PAGELANG.in_login"
        size="large"
        class="btn-login"
      />
      <van-button
        v-else
        color="var(--van-card-price-color)"
        block
        type="primary"
        native-type="submit"
        size="large"
        class="btn-login"
      >
        {{ PAGELANG.login_btn }}
      </van-button>
    </van-form>
  </div>

 
</template>
<style>
.van-icon-aog {
  color: #cfaf6d;
}
</style>
<style scoped>
@media (max-width: 768px) {
  .login-layout-pc {
    display: none;
  }
  .login-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  .login-layout .logo {
    position: relative;
    border-radius: 50%;
  }

  .login-layout .logo::before,
  .login-layout .logo::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: inherit;
  }

  .login-layout .logo::before {
    top: 1px;
    left: 1px;
    z-index: 10;
    background-color: #fff;
  }

  .login-layout .logo::after {
    top: 0;
    left: 0;
    z-index: 1;
    padding: 1px;
    background: linear-gradient(to right, #fff, #d5ba80);
  }

  .login-layout .logo .logoimg {
    z-index: 100;
  }

  .login-form {
    width: 90vw;
    margin-top: 9vw;
    --van-cell-horizontal-padding: 0;
    --van-padding-md: 0;
  }

  .van-icon-aog {
    color: #cfaf6d;
  }

  .forget {
    line-height: 38px;
    font-size: 12px;
    text-align: right;
  }

  .forget > a {
    color: #143949;
  }

  .btn-login {
    margin-top: 40px;
  }

  .register {
    text-align: center;
    padding-top: 20px;
    line-height: 44px;
    font-size: 12px;
    color: #979797;
  }

  .register > a {
    font-size: 14px;
    color: #143949;
    margin-left: 10px;
  }
}
</style>
<style src="../assets/css/login.css" scoped></style>

<script>
import { ref, getCurrentInstance } from "vue";


import md5 from 'md5'


import { closeToast, showNotify, showLoadingToast,showToast } from "vant";
import { getCookie, setCookie } from "../util/index.js";



export default {
 
  methods: {
    onConfirm({ selectedOptions }) {
      this.showPrefix = false;
      this.curPerfix = selectedOptions[0].text;
      setCookie("lh_aog_langcode", selectedOptions[0].value);
  

      location.reload();
    },
	
	//發送短信驗證碼
	sms(){
		this.axios.get(this.actions.getSmsToken,{params:{
          // cateid: this.cateid * 1,
		  phone:this.username,
          platformType:1,
        }}).then((response)=>{
			let { code, message } = response.data;
			if (code == "200") { 
				console.log(response)
			    this.getSms(response.data.data.token)
			}
			else{
				showToast({
				  message:message,
				  forbidClick: true,
				});
			}
		})
	},
	getSms(token){
		this.axios.get(this.actions.getSms,{params:{
		  // cateid: this.cateid * 1,
		  phone:this.username,
		  platformType:1,
		  token:token
		}}).then((response)=>{
			let { code, message } = response.data;
			if (code == "200") { 
				console.log(response)
			}
			else{
				showToast({
				  message:message,
				  forbidClick: true,
				});
			}
		})
	},

    onChangeLang(val) {
      this.langcode = val;
      setCookie("lh_aog_langcode", val);
      location.reload();
    },

    onRegister() {
      this.$refs.refLogin.validate((valid) => {
        if (valid) {
          this.invitecode = this.reginfo.invitecode;
          this.agentname = this.reginfo.agentrequired;
          this.password = this.reginfo.password;
          this.password2 = this.reginfo.password2;
          this.username = this.reginfo.username;
          this.email = this.reginfo.email ? this.reginfo.email : "";

          this.onSubmit();
        } else {
          return false;
        }
      });
    },
    //註冊經銷商  
    onSubmit() {
      console.log("submit");
      console.log(this.username, this.password, this.password2, this.agentname);

      this.isloading = true;

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      if (this.password != this.password2) {
        // showFailToast(this.PAGELANG.inconformity);
        closeToast();
        this.isloading = false;
        showNotify({
          message: this.PAGELANG.inconformity,
          type: "warning",
        });
        return;
      }

      let postobj = new Object();

	  postobj.userName = this.username;//經銷商名稱
      postobj.password = this.password; //密碼
	  postobj.passwordSec=this.password2;//確認密碼
      postobj.eMail = this.email;//郵箱
	  postobj.avatar="https://s.qinghuo.link/8a/01/aa/8a01aa41aba34a67b95fd328e99d1905.png";//頭像
	  postobj.identityCard=this.identityCard;//護照
	  postobj.inviteId=this.inviteId,//邀請人id
	  postobj.idType=2,
	  postobj.birthday="2024-01-1",
	  postobj.cityId="999991",
	  postobj.districtId="999992",
	  postobj.detail="台灣",
	  postobj.wechat="xxx",
	  postobj.provinceId="100001",

      console.log(postobj);
	  
	  
      postobj.password = md5(postobj.password)
	  postobj.passwordSec = md5(postobj.passwordSec)

      this.axios.post(this.actions.addInfo, postobj).then((response) => {
        //注册返回的数据
        console.log(response);

        this.isloading = false;

        let showinfo = this.PAGELANG[response.data.retinfo];

        if (!showinfo) {
          showinfo = response.data.message;
        }

        closeToast();

        if (response.data.code == "200") {
          //成功
          setCookie("agentlogintoken", response.data.data.token,30);

          // showSuccessToast({message: showinfo, onClose: () => {
          //   this.$router.push('/appflow');
          // }});
          showNotify({
            message:"註冊成功",
            type: "success",
			duration:1500,
            onClose: () => {
              this.$router.push("/custom/shen");
            },
          });
        } else {
          // showFailToast(showinfo);
          showNotify({
            message: showinfo,
            type: "warning",
          });
        }
      });
    },
  },

  mounted() {
    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

	 this.axios.get(this.actions.memberGetInvite).then((res)=>{
	 	this.inviteId = res.data.data.inviteId;
		console.log(this.inviteId)
	 }) 
    this.axios.get(this.actions.mobile_prefix).then((response) => {
      closeToast();
      this.mobileperfix = response.data;
      let langitem = this.mobileperfix.find((item) => {
        if (item.value == this.langcode) return true;
        return false;
      });

      if (langitem) {
        this.selectedlang = [langitem.value];
        this.curPerfix = langitem.text;
      }
    });
  },
  setup() {
    //const $axios = inject("$axios");
    //const $refs = inject("$refs");

    const _this = getCurrentInstance();
    const gp = _this.appContext.config.globalProperties;

    const PAGELANG = gp.LANG.pages.register;
    const SYSLANG = gp.LANG.system;

    const langcode = getCookie("lh_aog_langcode");

    const appContext = _this.appContext;
    const mobileperfix = ref([]);
    const curPerfix = ref("");
    const showPrefix = ref(false);
    const invitecode = ref("");
    const username = ref("");
    const password = ref("");
    const password2 = ref("");
    const agentname = ref("");
    const email = ref("");
    const isloading = ref(false);
	const identityCard = ref("");
	const inviteId=ref("");
    const rules = {
      username: [{ required: true, message:"請輸入姓名" }],
	  captcha:  [{ required: true, message: PAGELANG.yanzheng }],
      password: [{ required: true, message: PAGELANG.password }],
      password2: [{ required: true, message: PAGELANG.password2 }],
      agentrequired: [{ required: true, message: PAGELANG.agentrequired }],
	  email: [{ required: true, message: "請輸入郵箱" }],
	  identityCard: [{ required: true, message: "請輸入護照" }],
    };
    const selectedlang = ref([""]);
    const reginfo = ref([""]);

    return {
      PAGELANG,
      SYSLANG,
      mobileperfix,
      curPerfix,
      invitecode,
      username,
      password,
      password2,
      agentname,
      email,
      showPrefix,
      isloading,
      rules,
      selectedlang,
      appContext,
      langcode,
      reginfo,
	  identityCard,
	  inviteId
    };
  },
};
</script>